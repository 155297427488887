import React, { useEffect } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { EyeIcon, EyeOff } from 'lucide-react';
import { notification, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import Cookies from 'js-cookie';

import { AccessControl as AccessControlClass } from '../../services/apis/accessControlServices';
import { NotificationType } from '../../types/commonTypes';
import packageJason from '../../../package.json';

import styles from './newAccess.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

// Esquema de validação utilizando Yup
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
  password: Yup.string()
    .min(6, 'A senha deve ter pelo menos 6 caracteres')
    .required('A senha é obrigatória'),
});

export function NewAccessControl() {
    const version = packageJason.version;
    const navigate = useNavigate();
    const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);
    const [api, contextHolder] = notification.useNotification();
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [emailUser, setEmailUser] = React.useState('');
    const [remember, setRemember] = React.useState(false);
    const [showLogin, setShowLogin] = React.useState(false);

    useEffect(() => {
        const email = Cookies.get('remenberEmail');
        if (email) {
            setEmailUser(email);
            setRemember(true);
            setShowLogin(true);
        } else {
            setShowLogin(true);
        }
      }, []);

    const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
        api[type]({
          message: title,
          description: description,
        });
      };

    const handleSignIn = async (values: {email: string, password: string, staySignedIn?: boolean}) => {
        setLoading(true);
        try {
          const authServices = new AccessControlClass();
    
          if (values.staySignedIn) {
            Cookies.set('remenberEmail', values.email, { expires: 182, sameSite: 'strict' })
          } else {
            Cookies.remove('remenberEmail');
          }
    
          const respLogin = await authServices.signIn({
            email: values.email,
            password: values.password
          });
          
          navigate('/');
          setLoading(false);
        } catch (error: any) {
          openNotificationWithIcon('error', 'Erro ao efetuar login!', error.message);
          setLoading(false);
        }
      }
    
  return (
    <div 
      className={styles.loginContainer}
      style={{ backgroundColor: `${styleBySubDomain.backgroundColor}` }}
    >
        {contextHolder}
      <div className={styles.loginForm}>
        <div className={styles.boxLogo}>
            <img src={styleBySubDomain.logoImage} alt="Logo centro de Inovação" />
        </div>
        <h1>Seja bem-vindo!</h1>
        <p>Entre aqui com seus dados para acessar o sistema!</p>
        {showLogin && <Formik
          initialValues={{email: emailUser, password: '', staySignedIn: remember}}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSignIn(values);
            setSubmitting(false);
            // Aqui você adicionaria a lógica de autenticação
          }}          
        >
          {({ isSubmitting }) => (
            <Form>
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" />
              <ErrorMessage name="email" component="div" className={styles.error} />

              <label htmlFor="password">
                Senha             
              </label>
              <Field type="password" name="password">
                {({ field }: any) => (
                  <div className={styles.passwordField}>
                    <input type={!showPassword ? 'password' : 'text'} {...field} />
                    {!showPassword ? <EyeOff onClick={() => setShowPassword(!showPassword)} size={24} /> : <EyeIcon onClick={() => setShowPassword(!showPassword)} size={24} />}
                  </div>
                )}
              </Field>
              <ErrorMessage name="password" component="div" className={styles.error} />

              <div className={styles.checkboxContainer}>
                <Field type="checkbox" name="staySignedIn" id="staySignedIn"/>
                <label htmlFor="staySignedIn">Continuar conectado</label>
              </div>

              <button type="submit" disabled={isSubmitting}>
                Entrar
              </button>
            </Form>
          )}
        </Formik>}
        <Spin 
          tip="Loading" 
          size="large"
          className={styles.spinLoading}
          spinning={loading}
        >
          <div className={styles.contentSpin}/>
        </Spin>
        <div className={styles.links}>
          <a href="/registration/innovation-center">AINDA NÃO TEM CONTA? CRIE AQUI</a>
          <a href="#">ESQUECI MINHA SENHA</a>
        </div>
      </div>
        <div className={styles.boxVersion}>
          <p>© 2021 IntellectHub - Todos os direitos reservados - Versão {version} - HML</p>
        </div>
      <div 
        className={styles.loginImgContent}
        style={{ backgroundImage: `${styleBySubDomain.backgroundImage}` }}
      >
      </div>
    </div>
  );
};
