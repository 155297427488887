import { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  notification,
} from "antd";
import { HeaderFilters } from "../../../components/header_filters/headerFilters";
import { UserService } from "../../../services/apis/userService";
import { UserCard } from "./cardUser";
import { NotificationType } from "../../../types/commonTypes";
import {
  clearStringOnlyNumbers,
  inputMaskCPFCNPJ,
  inputMaskTELWithDDD,
} from "../../../libs/masks";
import Cookies from "js-cookie";

import styles from "./users.module.scss";

const { Option } = Select;

interface Startup {
  id: number;
  created_at: string;
  cnpj: string;
  cei: string;
  nome_fantasia: string;
  razao_social: string;
  categoria: string;
  data_fundacao: string;
  numero_funcionarios: string;
  cnae: string;
  contabilidade: string;
  valor_pagamento: string;
  contatos: {
    email: string;
    telefone: string;
    celular: string;
    site: string;
  };
  endereco: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    complemento: string;
  };
  owners: {
    nome_responsavel: string;
    cargo: string;
  };
  porte: string;
  instituition_owner: string;
  instituition_cnpj_owner: string;
  id_initial_create_enterprise: string;
}

interface User {
  id: number;
  id_auth: string;
  created_at: string;
  cpf: string;
  name: string;
  email: string;
  phone_number: string;
  instituition_id_owner: string;
  status: string;
  role: string;
}

export function Users() {
  const userServices = new UserService();
  const currentWidth = window.innerWidth;
  const currentProfile = Cookies.get("profile");
  const parseProfile = currentProfile ? JSON.parse(currentProfile) : null;
  const user = Cookies.get("user");
  const parseUser = user ? JSON.parse(user) : null;
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [users, setUsers] = useState([] as User[]);
  const [startups, setStartups] = useState([] as Startup[]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const descriptionRoles = {
    ADM: "Administrativo",
    ATENDIMENTO: "Chamados",
    EVENTOS: "Eventos",
  };

  const Label = (user: User) => {
    return (
      <div className={styles.label}>
        <span className={styles.labelNameUser}>{user.name}</span>
        <span className={styles.labelTitle}>Nivel de acesso:</span>
        <span className={styles.labelRole}>
          {descriptionRoles[user.role as keyof typeof descriptionRoles]}
        </span>
      </div>
    );
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      if (parseProfile[0].role === "ADM") {
        await userServices.createUserByAdmin({
          name: values.name,
          email: values.email,
          phone_number: clearStringOnlyNumbers(values.phone_number),
          cpf: clearStringOnlyNumbers(values.cpf),
          role: values.role,
          status: values.status,
          role_ref: parseProfile[0].role_ref,
          cnpjOwnerRef: parseProfile[0].institution_cnpj_owner,
          nameOwnerRef: parseProfile[0].institution_owner,
        });
      } else {
        await userServices.createUser({
          name: values.name,
          email: values.email,
          phone_number: clearStringOnlyNumbers(values.phone_number),
          cpf: clearStringOnlyNumbers(values.cpf),
          role: values.role,
          status: values.status,
          role_ref: parseProfile[0].role,
        });
      }
      setVisible(false);
      getAllUsers();
      openNotificationWithIcon(
        "success",
        "Sucesso",
        "Usuário criado com sucesso"
      );
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon("error", "Erro", error.message);
    }
  };

  const getAllUsers = async () => {
    if (parseProfile[0].role === "ADM") {
      const resp = await userServices.getUserByCnpjOwner(
        parseProfile[0].institution_cnpj_owner
      );
      const usersValid = resp.users.filter(
        (user: User) => user.id_auth !== parseUser.id
      );
      setUsers(usersValid);
      setStartups(resp.startups);
      return;
    } else {
      const resp = await userServices.getUsers();
      setUsers(resp.users);
      setStartups(resp.startups);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div>
      {contextHolder}
      <HeaderFilters
        text="Usuário"
        changeButton={() => {
          setVisible(true);
        }}
      />
      <Divider orientation="left">Ativos</Divider>
      {users.map((user) => {
        if (user.status === "active") {
          return (
            <Collapse
              items={[
                {
                  key: `${user.id}`,
                  label: Label(user),
                  children: <UserCard getAllUsers={getAllUsers} user={user} />,
                },
              ]}
            />
          );
        }
      })}
      <Divider orientation="left">Inativos</Divider>
      {users.map((user) => {
        if (user.status === "inactive") {
          return (
            <Collapse
              items={[
                {
                  key: `${user.id}`,
                  label: Label(user),
                  children: <UserCard getAllUsers={getAllUsers} user={user} />,
                },
              ]}
            />
          );
        }
      })}
      <Modal
        title="Criar Usuário"
        open={visible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setVisible(false);
        }}
        width={currentWidth < 768 ? "100%" : "50%"}
      >
        <Form
          className={styles.formContainer}
          onFinish={onFinish}
          form={form}
          layout="vertical"
        >
          <Form.Item
            className={styles.formItem}
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Campo nome é obrigatório" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Campo E-mail é obrigatório" },
              { type: "email", message: "E-mail invalido" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="Telefone"
            name="phone_number"
            rules={[
              { required: true, message: "Campo telefone é obrigatório" },
            ]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  phone_number: inputMaskTELWithDDD(e.target.value),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="CPF"
            name="cpf"
            rules={[{ required: true, message: "Campo CPF é obrigatório" }]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({ cpf: inputMaskCPFCNPJ(e.target.value) });
              }}
            />
          </Form.Item>

          <Form.Item
            name="role"
            label="Nivel de acesso"
            rules={[{ required: true }]}
            style={{
              display: "inline-block",
              width: "calc(29% - 8px)",
            }}
          >
            <Select placeholder="Selecione">
              <Option value="ADM">Administrativo</Option>
              <Option value="ATENDIMENTO">Chamado</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Campo status é obrigatório" }]}
            style={{
              display: "inline-block",
              width: "calc(29% - 8px)",
            }}
          >
            <Select placeholder="Selecione">
              <Option value="active">Ativo</Option>
              <Option value="inactive">Inativo</Option>
            </Select>
          </Form.Item>
          <div className={styles.formButtonContainer}>
            <Form.Item className={styles.formButton}>
              <button
                type="button"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Voltar
              </button>
            </Form.Item>
            <Form.Item className={styles.formButton}>
              <Button type="primary" htmlType="submit">
                Cadastrar
              </Button>
            </Form.Item>
          </div>
        </Form>
        <Spin spinning={loading} fullscreen />
      </Modal>
    </div>
  );
}
