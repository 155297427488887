export class Formatters {
    formatEvent(event: any) {
        return {
            title: event.title,
            dates: event.dates,
            description: event.description,
            movement: event.movement,
            location: event.location,
            access_link: event.accessLink,
            id_initial: event.id_initial,
        };
    }
}