import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Input, notification, Row, Space, Spin } from "antd";
import { FormProps } from "antd/lib";
import CryptoJS from 'crypto-js';

import { validaEmail } from "../../services/validation.service";
import { clearStringOnlyNumbers, inputMaskCPFCNPJ, inputMaskTELWithDDD } from "../../libs/masks";

import styles from "./registerUserStyles.module.scss";
import { UserService } from "../../services/apis/userService";
import { NotificationType } from "../../types/commonTypes";
import { ProfileServices } from "../../services/apis/profileServices";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface FieldType {
    name: string;
    email: string;
    cpf: string;
    phone: string;
    password: string;
    confirm_password: string;
}

export function UserRegistration() {
    const { user } = useParams();
    const [form] = Form.useForm();
    const [password, setPassword] = useState('');
    const [showRequirements, setShowRequirements] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [parseProfile, setParseProfile] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const styleSliceReducer = useSelector((state: RootState) => state.styleSlice);

    const [api, contextHolder] = notification.useNotification();

    const currentWidth = window.innerWidth;
    const userServices = new UserService();

    const openNotificationWithIcon = (
        type: NotificationType,
        title: string,
        description: string
      ) => {
        api[type]({
          message: title,
          description: description,
        });
      };

    const getProfile = async () => {
        const profileServices = new ProfileServices();
        try {
            const response = await profileServices.getProfileByIdPublic(user as string);
            setParseProfile(response.data[0])
        } catch (error: any) {
            openNotificationWithIcon('error', 'Erro ao tentar buscar perfil', error.message);
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handlePasswordFocus = () => {
        setShowRequirements(true);
    };

    const handlePasswordBlur = () => {
        setShowRequirements(false);
    };

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        setLoading(true);
      try {
        await userServices.createUserPublic({
            name: values.name,
            email: values.email,
            phone_number: clearStringOnlyNumbers(values.phone),
            cpf: clearStringOnlyNumbers(values.cpf),
            role: 'EVENTOS',
            status: 'active',
            role_ref: parseProfile.role,
            cnpjOwnerRef: parseProfile.cnpj,
            nameOwnerRef: parseProfile.name_user,
            password: values.confirm_password,
            events_register_permission: true,
            instituition_id_owner: parseProfile.id_auth,
          });
          openNotificationWithIcon('success', 'Usuário cadastrado com sucesso', 'O usuário foi cadastrado com sucesso!');
            setLoading(false);
      } catch (error: any) {
        openNotificationWithIcon('error', 'Erro ao tentar cadastrar usuário', error.message);   
        setLoading(false);     
      }
    };
      
    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const maskedValue = inputMaskTELWithDDD(clearStringOnlyNumbers(event.target.value));
        setPhoneNumber(maskedValue); // Atualiza o estado local
        form.setFieldsValue({ phone: inputMaskTELWithDDD(event.target.value) }); // Atualiza o valor no formulário
    };

    const PasswordRequirements = ({ password }: {password: string}) => {
        const hasLength = password.length >= 6;  // Alterado de 8 para 6 caracteres
        const hasNumbers = /\d/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
        return (
            <div 
                style={{ 
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px', 
                    marginBottom: '10px'
                }}
            >
                <p style={{ color: hasLength ? 'green' : 'red' }}>Mínimo 6 caracteres</p>
                <p style={{ color: hasNumbers ? 'green' : 'red' }}>Contém um número</p>
                <p style={{ color: hasUpper ? 'green' : 'red' }}>Contém uma letra maiúscula</p>
                <p style={{ color: hasLower ? 'green' : 'red' }}>Contém uma letra minúscula</p>
                <p style={{ color: hasSpecial ? 'green' : 'red' }}>Contém um caractere especial</p>
            </div>
        );
    };

    useEffect(() => {
        getProfile();
    }, [user]);

    return (
        <div 
            className={styles.container}
            style={{
                backgroundColor: styleSliceReducer.backgroundColor,
                color: '#FFFFFF',
            }}
        >
            {contextHolder}
            <h1>Cadastre-se para ter acesso ao cadastro de eventos</h1>
            <Form
                name="user_registration"
                layout="vertical"
                className={styles.form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
                    className={styles.form_item}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[{ 
                        required: true, 
                        message: 'Por favor, insira seu e-mail!',
                        validator(rule, value, callback) {
                            if (!value) {
                                return Promise.resolve();
                            }
                            if (!validaEmail(value)) {
                                return Promise.reject('E-mail inválido!');
                            }
                            return Promise.resolve();
                        }, 
                    }]}
                    className={styles.form_item}
                >
                    <Input />
                </Form.Item>
                <Row
                    gutter={16}
                    style={{ 
                        width: '100%',
                        display: 'flex', 
                        gap: '16px',
                    }}
                >
                    <Form.Item
                        label="CPF"
                        name="cpf"
                        rules={[{ 
                            required: true, 
                            message: 'Por favor, insira seu CPF!',
                            validator(rule, value, callback) {
                                if (value) {
                                    return Promise.resolve();
                                }
                                if (value.length !== 11) {
                                    return Promise.reject('CPF inválido!');
                                }
                                return Promise.resolve();
                            }, 
                        }]}
                        style={{ width: currentWidth > 1024 ? '49%' : '100%' }}
                        className={styles.form_item}
                    >
                        <Input 
                            onChange={
                                (event) => {
                                    const value = inputMaskCPFCNPJ(event.target.value);
                                    form.setFieldsValue({ cpf: value });
                                }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Telefone"
                        name="phone"
                        rules={[{ 
                            required: true, 
                            message: 'Por favor, insira seu telefone!', 
                        }]}
                        className={styles.form_item}
                        style={{ width: currentWidth > 1024 ? '49%' : '100%' }}
                    >
                        <Input 
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                        />
                    </Form.Item>
                </Row>
                <Row
                    gutter={16}
                    style={{ 
                        width: '100%',
                        display: 'flex', 
                        gap: '16px',
                    }}
                >
                    <Form.Item<FieldType>
                        label="Senha"
                        name="password"
                        rules={[{ 
                            required: true, 
                            message: 'Por favor, insira sua senha!',
                            validator(rule, value, callback) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                if (value.length < 6) {
                                    return Promise.reject('A senha deve ter no mínimo 6 caracteres!');
                                }
                                return Promise.resolve();
                            } 
                        }]}
                        style={{ 
                            width: currentWidth > 1024 ? '49%' : '100%' 
                        }}
                        className={styles.form_item_password}
                    >
                        <Input.Password 
                            onChange={handlePasswordChange} 
                            onFocus={handlePasswordFocus} 
                            onBlur={handlePasswordBlur} 
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="Confirme sua senha"
                        name="confirm_password"
                        rules={[{ 
                            required: true, 
                            message: 'Por favor, confirme sua senha!'
                        }, ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('As senhas não conferem!');
                            },
                        })]}
                        className={styles.form_item_password}
                        style={{ width: currentWidth > 1024 ? '49%' : '100%' }}
                        >
                        <Input.Password />
                    </Form.Item>
                    {showRequirements && <PasswordRequirements password={password} />}
                </Row>
                <Form.Item>
                    <Space>
                        <Button
                            type="primary"
                            danger
                            ghost
                            size="large"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                        >
                            Cadastrar
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Spin 
                fullscreen
                size="large"
                spinning={loading}
            />
        </div>
    );
}
