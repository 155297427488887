import { useEffect, useState } from "react";
import { InputSelect } from "../../../components/inputSelect/inputTextFilter";
import { InputText } from "../../../components/inputText/inputText";
import { clearStringOnlyNumbers, inputMaskCPFCNPJ, inputMaskTELWithDDD } from "../../../libs/masks";
import { ButtonCustom } from "../../../components/customButtom/buttomCustom";
import { UserService } from "../../../services/apis/userService";
import { ConfigProvider, Spin, Tooltip, notification } from "antd";
import { NotificationType } from "../../../types/commonTypes";

import styles from './users.module.scss';

export function UserCard({ user, getAllUsers }: any) {
    const userService = new UserService();
    const [api, contextHolder] = notification.useNotification();
    const [name, setName] = useState('' as string);
    const [role, setRole] = useState('' as string);
    const [status, setStatus] = useState('' as string);
    const [cpf, setCPF] = useState('' as string);
    const [phone, setPhone] = useState('' as string);
    const [email, setEmail] = useState('' as string);
    const [loading, setLoading] = useState(false);

    const descriptionRoles = {
      ADM: "Administrativo",
      ATENDIMENTO: "Chamados",
      EVENTOS: "Eventos",
    };

    const roleRevertDescription = {
        Administrativo: 'ADM',
        Chamados: 'ATENDIMENTO',
        Eventos: 'EVENTOS',
    }

    const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
        api[type]({
          message: title,
          description: description,
        });
      };

    const debounce = (fn: any, delay: number = 1000) => {
        let timer: any;
        return function (this: any, ...args: any) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, args);
            }, delay);
        };
    }

    const handleUpadateUser = async (id: number, data: any) => {
        setLoading(true);
        try {
            const response = await userService.upadateUser(id, data);
            openNotificationWithIcon('success', 'Usuário atualizado com sucesso', 'Usuário atualizado com sucesso');
            getAllUsers();
            setLoading(false);
            return;
        } catch (error: any) {
            openNotificationWithIcon('error', 'Erro ao atualizar usuário', error.message);
            setLoading(false);
            return;
        }
    }

    const handleRoleChange = debounce(async (id: number, roleChange: string) => {
        setRole(role);
        handleUpadateUser(id, { 
            ...user,
            role: roleRevertDescription[roleChange as keyof typeof roleRevertDescription]
         })        
    });

    const handleStatusChange = debounce(async (id: number, status: string) => {
        setStatus(status);
        handleUpadateUser(id, { 
            ...user,
            status: status
         })
    });

    const handleChangeText = async () => {
        handleUpadateUser(user.id, { 
            ...user,
            name: name,
            cpf: clearStringOnlyNumbers(cpf),
            phone_number: clearStringOnlyNumbers(phone),
            email: email
         })
    };

    useEffect(() => {
        setName(user.name);
        setRole(descriptionRoles[user.role as keyof typeof descriptionRoles]);
        setStatus(user.status);
        setCPF(user.cpf);
        setPhone(user.phone_number);
        setEmail(user.email);
    }, [user]);


    return (
        <ConfigProvider>
            {contextHolder}
            <div className={styles.userCard}>
                <div className={styles.userInfo}>
                    <div className={styles.boxUserLabelCard}>
                        <InputText 
                            label="Nome"
                            name="name"
                            value={name}
                            styleLabel={{
                                color: 'var(--blue-900)'
                            }}
                            style={{
                                border: '1px solid var(--blue-900)',
                                padding: '0.5rem',
                            }}
                            onChange={(e) => {
                                    setName(e.target.value)
                                }
                            }
                            inputProps={{
                                style: {
                                    color: 'var(--blue-900)',
                                    font: '400 0.75rem Roboto, sans-serif',
                                }
                            }}
                        />
                    </div>
                    <div className={styles.boxUserLabelCard}>
                        <InputSelect 
                            className={styles.select}
                            options={[
                                { value: 'Administrativo', sigla: 'ADM' },
                                { value: 'Chamados', sigla: 'ATENDIMENTO' },
                                { value: 'Eventos', sigla: 'EVENTOS' }
                            ]}
                            label="Permissão"
                            value={role}
                            onChange={(value) => {
                                handleRoleChange(user.id, value)
                                setRole(value)
                            }}
                            styleLabel={{
                                color: 'var(--blue-900)'
                            }}
                            style={{
                                border: '1px solid var(--blue-900)',
                                height: '2rem',
                            }}
                        />
                    </div>
                    <div className={styles.boxUserLabelCard}>
                        <InputSelect 
                            className={styles.select}
                            options={[
                                { value: 'active', sigla: 'active' },
                                { value: 'inactive', sigla: 'inactive' }
                            ]}
                            label="Status"
                            value={status}
                            onChange={(value) => {
                                handleStatusChange(user.id, value)
                                setStatus(value)
                            }}
                            styleLabel={{
                                color: 'var(--blue-900)'
                            }}
                            style={{
                                border: '1px solid var(--blue-900)',
                                height: '2rem',
                            }}
                        />
                    </div>
                    <div className={styles.boxUserLabelCard}>
                        <InputText 
                            label="CPF"
                            name="CPF"
                            value={inputMaskCPFCNPJ(cpf)}
                            styleLabel={{
                                color: 'var(--blue-900)'
                            }}
                            style={{
                                border: '1px solid var(--blue-900)',
                                padding: '0.5rem',
                            }}
                            onChange={(e) => {
                                    setCPF(e.target.value)
                                }
                            }
                            inputProps={{
                                style: {
                                    color: 'var(--blue-900)',
                                    font: '400 0.75rem Roboto, sans-serif',
                                }
                            }}
                        />
                    </div>
                    <div className={styles.boxUserLabelCard}>
                        <InputText 
                            label="Telefone"
                            name="phone_number"
                            value={inputMaskTELWithDDD(phone)}
                            styleLabel={{
                                color: 'var(--blue-900)'
                            }}
                            style={{
                                border: '1px solid var(--blue-900)',
                                padding: '0.5rem',
                            }}
                            onChange={(e) => {
                                    setPhone(e.target.value)
                                }
                            }
                            inputProps={{
                                style: {
                                    color: 'var(--blue-900)',
                                    font: '400 0.75rem Roboto, sans-serif',
                                }
                            }}
                        />
                    </div>
                    <Tooltip 
                        placement="top" 
                        title={'E-mail não pode ser substituido'} 
                        arrow={true}
                        trigger={['hover', 'click']}
                    >
                        <div className={styles.boxUserLabelCard}>
                                <InputText 
                                    label="E-mail"
                                    name="email"
                                    value={email}
                                    styleLabel={{
                                        color: 'var(--blue-900)'
                                    }}
                                    style={{
                                        border: '1px solid var(--blue-900)',
                                        padding: '0.5rem',
                                    }}
                                    onChange={(e) => {
                                            setEmail(e.target.value)
                                        }
                                    }
                                    inputProps={{
                                        style: {
                                            color: 'var(--blue-900)',
                                            font: '400 0.75rem Roboto, sans-serif',
                                        }
                                    }}
                                    disabled={true}
                                />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.containerAction}>
                <ButtonCustom 
                    label="Salvar"
                    onClick={() => handleChangeText()}
                    style={{
                        width: '20%',
                        marginRight: '1rem',
                        height: '2.3rem',
                    }}
                />
                {/*<ButtonCustom
                    label="Cancelar"
                    onClick={() => {}}
                    style={{
                        width: '20%',
                        backgroundColor: 'var(--red-500)',
                        height: '2.3rem',
                    }}
                />*/}
            </div>
            <Spin spinning={loading} fullscreen />
        </ConfigProvider>
    );
};