import { 
  ChangeEvent,
  useEffect, 
  useState 
} from "react";
import { 
  Button, 
  Checkbox, 
  DatePicker, 
  Form, 
  Input, 
  Select, 
  Upload, 
  UploadProps, 
  message
} from "antd";
import { 
  useSelector, 
  useDispatch 
} from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { UploadIcon } from "lucide-react";

import Cookies from "js-cookie";

import { category_tikets } from "../../constants/tikets";
import { FilesServices } from "../../services/apis/filesServices";
import { TiketsServices } from "../../services/apis/tiketsServices";
import { RootState } from "../../store/store";
import { 
  resetForm,
  setCategory,
  setDateOcurrency,
  setDescription, 
  setIdInitial, 
  setPriority, 
  setTitle 
} from "../../store/tiketsSlice";

import styles from "./newTiketStyle.module.scss";
import { ScreensConfirmation } from "../../components/confirmationScreen/screens";
import { setFeatureScreenConfirmation, setMessageScreenConfirmation, setScreenConfirmation, setSubMessageScreenConfirmation } from "../../store/screenConfirmationSlice";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

interface FormValues {
    title: string;
    description: string;
    priority: 'baixa' | 'media' | 'alta';
  }
  
export function NewTiketsPage() {
  const navigate = useNavigate();
  const userToken = Cookies.get('access_token');
  const dispatch = useDispatch();
  const fileServices = new FilesServices();
  const ticketServices = new TiketsServices();
    const [selectedDate, setSelectedDate] = useState(false);

    const createInitialId = () => {
      const id = uuidv4();
      dispatch(setIdInitial(id));
      return id;
    }

    const tiketsState = useSelector((state: RootState) => state.tiketsCreate);
    const screensState = useSelector((state: RootState) => state.screenStatus);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        switch (name) {
            case 'title':
                 dispatch(setTitle(value));
                break;
            case 'date':
                 dispatch(setDateOcurrency(value));
                break;
            case 'category':
                 dispatch(setCategory(value));
                break;
            case 'priority':
                 dispatch(setPriority(value));
                break;
            default:
                break;
        }
    };

    const handleDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        dispatch(setDescription(value));
    };

    const onFinish = async (values: FormValues) => {
      dispatch(setScreenConfirmation("pending"));
      dispatch(setFeatureScreenConfirmation("tiket"));
      try {
        await ticketServices.createTiket(tiketsState);
        dispatch(setScreenConfirmation("approved"));
        dispatch(setFeatureScreenConfirmation("Chamado aberto com sucesso!"));
        dispatch(setMessageScreenConfirmation("Seu chamado foi aberto com sucesso!"));

        dispatch(resetForm());
        navigate('/tikets');
      } catch (error: any) {
        dispatch(setScreenConfirmation("rejected"));
        dispatch(setMessageScreenConfirmation("Erro ao abrir chamado!"));
        dispatch(
          setSubMessageScreenConfirmation(
            error.message ?? "Erro inesperado, entre em contato com o suporte"
          )
        );
      }
    };

    const deleteFile = async (fileName: string) => {
      try {
        const response = await fileServices.deleteAttachment(fileName);
        return response;
      } catch (error) {
        return;
      }
    }

    const handleUpload: UploadProps = {
      //action: 'http://localhost:4001/files/attachments-tikets', //local
      action: 'https://orca-app-so9hc.ondigitalocean.app/files/attachments-tikets', //stage
      headers: {
        Authorization: `Bearer ${userToken}`,
        'X-Custom-Header': tiketsState.id_initial ? tiketsState.id_initial : createInitialId(),
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onRemove(file: any) {
        deleteFile(file.response.fileName);
      }
    };

    useEffect(() => {
      createInitialId();
    }, []);
    
      return (
        <>
          {screensState.feature !== 'tiket' &&
          <div 
            className={styles.container}
          >
            <h1>Preencha as informações para abertura do chamado</h1>
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Título do Chamado"
                name="title"
                rules={[{ required: true, message: 'Por favor, insira o título do chamado!' }]}
              >
                <Input 
                  placeholder="Digite o título do chamado" 
                  onChange={handleChange}
                  value={tiketsState.title}
                  name="title"
                />
              </Form.Item>
              <Form.Item
                label="Descrição"
                name="description"
                rules={[{ required: true, message: 'Por favor, insira uma descrição detalhada do problema!' }]}
              >
                <Input.TextArea 
                  rows={4} 
                  placeholder="Descreva o problema detalhadamente" 
                  onChange={handleDescription}
                  value={tiketsState.description}
                  name="description"
                />
              </Form.Item>
              <Form.Item
                  name="dateOcurrency"
                  valuePropName="checked"
              >
                  <Checkbox
                      onChange={(e) => 
                          setSelectedDate(e.target.checked)
                      }
                  >
                      <span style={{ marginLeft: 8 }}>Selecionar data e hora da ocorrência</span>
                  </Checkbox>
              </Form.Item>
              {selectedDate && (
                  <Form.Item
                      label="Data e hora da ocorrência"
                      name="date"
                      rules={[{ required: true, message: 'Por favor, selecione a data e hora da ocorrência!' }]}
                  >
                      <DatePicker 
                        showTime format="YYYY-MM-DD HH:mm:ss" 
                        onChange={(date, dateString) => handleChange({ target: { value: dateString, name: 'date' } } as ChangeEvent<HTMLInputElement>)}
                        name="date"
                      />
                  </Form.Item>
              )}
              <Form.Item
                label="Categoria"
                name="category"
                rules={[{ required: true, message: 'Por favor, selecione a categoria do chamado!' }]}
              >
                  <Select 
                      placeholder="Selecione a categoria"
                      options={category_tikets.map((category) => ({ label: category.name, value: category.name }))}
                      onChange={(value) => handleChange({ target: { value, name: 'category' } } as ChangeEvent<HTMLInputElement>)}
                  />
              </Form.Item>
              <Form.Item
                label="Prioridade"
                name="priority"
                rules={[{ required: true, message: 'Por favor, selecione a prioridade do chamado!' }]}
              >
                <Select 
                  placeholder="Selecione a prioridade"
                  onChange={(value) => handleChange({ target: { value, name: 'priority' } } as ChangeEvent<HTMLInputElement>)}
                >
                  <Option value="baixa">Baixa</Option>
                  <Option value="media">Média</Option>
                  <Option value="alta">Alta</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Anexar arquivos"
                name="file"
              >
                <Upload {...handleUpload}>
                  <Button icon={<UploadIcon size={15}/>} type="default">Anexar arquivos</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Abrir Chamado
                </Button>
              </Form.Item>
            </Form>
          </div>}
          {screensState.feature === 'tiket' &&
            <ScreensConfirmation 
              status={screensState.screenStatus}
              feature={screensState.feature}
              subTitle={screensState.subMessage}         
              navigateTo="/"
              buttonError="Voltar para o início"
              buttonSuccess="Voltar para o início"
            />
          }
        </>
      );
}