import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Drawer as AntdDrawer, Menu, MenuProps } from "antd";
import styles from "./styleDrawer.module.scss";
import { Divider } from "../../../divider";
import AvatarDefaut from "../../../../assets/images/User-avatar.svg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Bolt,
  Briefcase,
  Building2,
  CalendarRange,
  ClipboardMinus,
  DoorOpen,
  File,
  Headset,
  HeartHandshake,
  Home,
  Info,
  Layers,
  Lock,
  LogOut,
  LucideIcon,
  PackagePlus,
  Settings,
  ShieldAlert,
  ShoppingCart,
  User,
  UserPlus,
  Wallet,
  XIcon,
} from "lucide-react";
import { ROLES } from "../../../../constants/roles";
import CopyToClipboard from "react-copy-to-clipboard";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { toggleDrawer } from "../../../../store/layoutSlice";
import path from "path";

interface Path {
  name: string;
  path: string;
  icon: LucideIcon;
  active?: boolean;
}

export default function Drawer() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname; 
  const userProfile = Cookies.get('profile')
  const profile = userProfile ? JSON.parse(userProfile) : null;
  const user = userProfile ? JSON.parse(userProfile) : null;
  const dispatch = useDispatch();
  const layoutReducer = useSelector((state: RootState) => state.layoutSlice);
  const styleMenu = useSelector((state: RootState) => state.styleSlice);

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  function LabelItemMenu(label: string, icon: React.ReactNode) {
    return (
      <div className={styles.labelItemMenu}>
        {icon}
        <span>{label}</span>
      </div>
    );
  }

  const itensMenu: any = {
    innovation: [
      getItem(LabelItemMenu('Painel', <Home />), '/'),
      getItem(LabelItemMenu('Instituições', <Briefcase />), 'sub 1', '', [
        getItem(LabelItemMenu('Empresas', <Building2 />), '/start-up'),
        getItem(LabelItemMenu('Fornecedores', <PackagePlus />), '/suppliers'),
        getItem(LabelItemMenu('Patrocinadores', <HeartHandshake />), '/sponsors'),
      ]),
      getItem(LabelItemMenu('Chamados', <Headset />), '/tikets'),
      getItem(LabelItemMenu('Eventos', <CalendarRange />), '/events'),
      getItem(LabelItemMenu('Salas', <DoorOpen />), '/rooms'),
      getItem(LabelItemMenu('Controle de danos', <ShieldAlert />), '/damage_control'),
      getItem(LabelItemMenu('Financeiro', <Wallet />), '/financial'),
      getItem(LabelItemMenu('Relatórios', <ClipboardMinus />), '#'),
      getItem(LabelItemMenu('Configurações', <Bolt />), 'sub 2', '', [
        getItem(LabelItemMenu('Colaboradores', <User />), '/config/users'),
      ]),
    ],
    startup: [
      getItem(LabelItemMenu('Painel', <Home />), '/'),
      getItem(LabelItemMenu('Chamados', <Headset />), '/tikets'),
      getItem(LabelItemMenu('Salas', <DoorOpen />), '/rooms'),
      getItem(LabelItemMenu('Configurações', <Bolt />), 'sub 2', '', [
        getItem(LabelItemMenu('Colaboradores', <User />), '/config/users'),
      ]),
    ],
    ADM: profile[0]?.role_ref === 'innovation' ? [
      getItem(LabelItemMenu('Painel', <Home />), '/'),
      getItem(LabelItemMenu('Instituições', <Briefcase />), 'sub 1', '', [
        getItem(LabelItemMenu('Empresas', <Building2 />), '/start-up'),
        getItem(LabelItemMenu('Fornecedores', <PackagePlus />), '/suppliers'),
        getItem(LabelItemMenu('Patrocinadores', <HeartHandshake />), '/sponsors'),
      ]),
      getItem(LabelItemMenu('Chamados', <Headset />), '/tikets'),
      getItem(LabelItemMenu('Eventos', <CalendarRange />), '/events'),
      getItem(LabelItemMenu('Salas', <DoorOpen />), '/rooms'),
      getItem(LabelItemMenu('Controle de danos', <ShieldAlert />), '/damage_control'),
      getItem(LabelItemMenu('Financeiro', <Wallet />), '/financial'),
      getItem(LabelItemMenu('Relatórios', <ClipboardMinus />), '#'),
      getItem(LabelItemMenu('Configurações', <Bolt />), 'sub 2', '', [
        getItem(LabelItemMenu('Colaboradores', <User />), '/config/users'),
      ]),
    ] : [
      getItem(LabelItemMenu('Chamados', <Headset />), '/tikets'),
      getItem(LabelItemMenu('Salas', <DoorOpen />), '/rooms'),
      getItem(LabelItemMenu('Configurações', <Bolt />), 'sub 2', '', [
        getItem(LabelItemMenu('Colaboradores', <User />), '/config/users'),
      ]),
    ],
    ATENDIMENTO: [
      getItem(LabelItemMenu('Chamados', <Headset />), '/tikets'),
      getItem(LabelItemMenu('Salas', <DoorOpen />), '/rooms'),
    ],
  }
const items: MenuProps['items'] = itensMenu[profile[0]?.role];
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key as string);
    dispatch(toggleDrawer());
  };

  // const navItems: Path[] = useMemo(() => {
  //   const paths = [
  //     {
  //       name: "Painel",
  //       path: "/",
  //       icon: Home,
  //     },
  //     {
  //       name: "Instituições",
  //       path: "/institutions",
  //     }
  //   ];
  //   const fullPaths =
  //     user?.role === ROLES.innovation
  //       ? [
  //           {
  //             name: "Acesso Admin",
  //             path: "/admin",
  //             icon: Lock,
  //           },
  //           ...paths,
  //         ]
  //       : paths;
  //   return fullPaths.map((path) => {
  //     return { ...path, active: location.pathname === path.path };
  //   });
  // }, [location]);

  //const handleNavigate = useCallback(
  //  (path: string) => {
  //    if (path === location.pathname) {
  //      return;
  //    }
  //    navigate(path);
  //    setDrawerOpen(!drawerOpen);
  //  },
  //  [drawerOpen, location, navigate, setDrawerOpen]
  //);

  //useEffect(() => {
  //  if (user) {
  //    getFiles('avatar', user?._id ?? '');
  //  }
  //}, [user]);

  //const avatar = files.find(avatar => avatar.fieldName === 'avatar') ?? null;

  return (
    <aside>
      <AntdDrawer
        width={window.innerWidth * 0.65}
        title={
          <img 
            width={'80%'} 
            height={'auto'}
            src={styleMenu.logoImage} 
            alt="Logo IntellectHub" 
            onClick={() => {
              dispatch(toggleDrawer());
            }}
          />}
        placement="left"
        onClose={() => {
          dispatch(toggleDrawer());
        }}
        open={layoutReducer.activeDrawer}
        style={{
          backgroundColor: styleMenu.backGroundColorMenu,
          color: '#FFFFFF',
        }}
        closeIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: "100%",
            }}
          >
            <XIcon color="#FFFFFF" size={35}/>
          </div>
        }
      >
        <div className={styles.container}>
          <div 
            className={styles.navList}
            style={{ backgroundColor: styleMenu.backGroundColorMenu }}
          >
          <Menu
            onClick={onClick}
            style={{ 
              width: 256, 
              backgroundColor: `${styleMenu.backGroundColorMenu}`,
            }}
            defaultSelectedKeys={[currentPath]}
            defaultOpenKeys={[currentPath]}
            mode="inline"
            theme={'dark'}
            items={items}
          />
          </div>
        </div>
      </AntdDrawer>
    </aside>
  );
}
