import axios from "axios";
import { api } from "./configs";

export class TiketsServices {
    createTiket = async (data: any) => {
        try {
            const response = await api.post(`/tickets/create-ticket`, {
                ...data
            });
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    getByCnpjInnovationOwner = async (cnpj: string) => {
        try {
            const response = await api.get(`/tickets/read-tickets/${cnpj}`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    getTikets = async () => {
        try {
            const response = await api.get(`/tickets/read-tickets`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    getTicketsByEnterprise = async () => {
        try {
            const response = await api.get(`/tickets/read-tickets/enterprise`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }


    getTiket = async (id: string) => {
        try {
            const response = await api.get(`/tickets/read-ticket/${id}`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    updateTiket = async (id: any, data: any) => {
        try {
            const response = await api.put(`/tickets/update-ticket/${id}`, {
                ...data
            });
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            }
            else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }

    deleteTiket = async (id: string) => {
        try {
            const response = await api.delete(`/tikets/delete/${id}`);
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                // Acessar a mensagem específica do erro do servidor
                console.error(error.response.data.message);
                throw new Error(error.response.data.message || 'Unknown error');
            } else {
                // Erro que não é de resposta HTTP, por exemplo, erro de rede
                console.error('Error sending request', error.message);
                throw new Error(error.message || 'Error sending request');
            }
        }
    }
}