import { useEffect, useRef, useState } from "react";
import { Button, Col, Descriptions, Modal, Row, Select } from "antd";
import { ChevronLeft, ChevronRight, Filter } from "lucide-react";

import FullCalendar from "@fullcalendar/react";
import CalendarOptions from '@fullcalendar/react';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from '@fullcalendar/multimonth'
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import moment from "moment";
import { Calendar } from '@fullcalendar/core';

import { HeaderFilters } from "../../components/header_filters/headerFilters";
import { EventDetails } from "./EventDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { EventsServices } from "../../services/apis/eventsServices";
import { Idate, IEvent } from "../../types/eventTypes";

import styles from "./events.module.scss";
import Cookies from "js-cookie";

interface IEditable {
  visible: boolean;
  eventId: number | null;
}

export function PublicEventsPage() {
  const calendarRef = useRef<FullCalendar>(null);
  const eventService = new EventsServices();
  const userProfile = Cookies.get('profile');
  const user = JSON.parse(userProfile || '{}');
  const [finalEvents, setFinalEvents] = useState<IEvent[]>([]);
  const [event, setEvent] = useState<any>();
  const [newEventVisible, setNewEventVisible] = useState(false);
  const [eventDetailsVisible, setEventDetailsVisible] = useState(false);
  const [editable, setEditable] = useState<IEditable>({
    visible: false,
    eventId: null,
  });
  const [visibleModalFiltered, setVisibleModalFiltered] = useState(false);
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);

  const getFormattedDateRange = (dates: Idate[]) => {
    if (dates.length === 0) return "";
  
    const sortedDates = dates.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    const firstDate = moment(sortedDates[0].start);
    const lastDate = moment(sortedDates[sortedDates.length - 1].end);
  
    if (firstDate.isSame(lastDate, 'day')) {
      // Se o evento começa e termina no mesmo dia
      return firstDate.format("DD [de] MMMM [de] YYYY");
    } else {
      // Se o evento abrange múltiplos dias
      return `de ${firstDate.format("DD [de] MMMM")} a ${lastDate.format("DD [de] MMMM [de] YYYY")}`;
    }
  };

  const getEvents = async () => {
    try {
      const response = await eventService.getAllPublicEvents();
      const newEvents = response.flatMap((event: IEvent) =>
        event.dates.map((date: Idate) => ({
          title: event.title,
          start: moment(date.start).toDate(),
          end: moment(date.end).toDate(),
          description: event.description,
          movement: event.movement,
          location: event.location,
          accessLink: event.access_link,
          files: event.files,
          user_id: event.user_created_id,
          id_initial: event.id_initial,
          event_id: event.id,
          dateRange: getFormattedDateRange(event.dates),
        }))
      );
      setFinalEvents(newEvents); // Atualiza o estado de finalEvents
      return;
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleNewEvent = () => {
    setNewEventVisible(true);
  };

  const handleViewChange = (value: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.changeView(value);
      setCurrentView(value);
      setVisibleModalFiltered(false)
    }
  };

  const handlePrev = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.prev();
      setVisibleModalFiltered(false)
    }
  };

  const handleNext = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.next();
      setVisibleModalFiltered(false)
    }
  };

  const handleToday = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.today();
      setVisibleModalFiltered(false)
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <div
        className={`${
          styleBySubDomain.backGroundColorMenu === "var(--purple-800)"
            ? styles.calendarContainerElume
            : styles.calendarContainerBase
        }`}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
          headerToolbar={{
            left: window.innerWidth > 768 ? "prev,next today" : '',
            center: "title",
            right: window.innerWidth > 768 ? "multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay" : "myCustomButton",
          }}
          customButtons={{
            myCustomButton: {
              text: 'Filtros',
              click: function() {
                setVisibleModalFiltered(true)
              }
            }
          }}
          eventClick={(info) => {
            setEvent(info);
            setEventDetailsVisible(true);
          }}
          eventColor={styleBySubDomain.backGroundColorMenu}
          locale={ptBrLocale}
          events={finalEvents}
          selectable
          nowIndicator
          expandRows
          slotDuration="01:00:00"
          allDaySlot={false}
          initialView="dayGridMonth"
        />
      </div>

      <Modal
       open={visibleModalFiltered}
       onCancel={() => setVisibleModalFiltered(false)}
       title='Escolha a forma de visualização'
       okButtonProps={{
        style: {
          display: "none"
        }
       }}
       cancelButtonProps={{
        style: {
          display: "none"
        }
       }}
      >
        <Descriptions.Item>   
            Selecione o intervalo de visualização desejado.     
        </Descriptions.Item>
        <Row 
          gutter={8}
          style={{
            marginTop: '0.5rem',
            marginBottom: '1rem'
          }}
        >
          <Col>
            <Button type="primary" icon={<ChevronLeft/>} onClick={handlePrev}></Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleToday}>Hoje</Button>
          </Col>
          <Col>
            <Button type="primary" icon={<ChevronRight/>} onClick={handleNext}></Button>
          </Col>
        </Row>
        <Descriptions.Item>   
          Formato de visualização:     
        </Descriptions.Item>
        <Row gutter={8}
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.75rem'
          }}
        >
          <Col span={24}>
            <Select defaultValue={currentView} style={{ width: '100%' }} onChange={handleViewChange}>
              <Select.Option value="dayGridMonth">Mês</Select.Option>
              <Select.Option value="timeGridWeek">Semana</Select.Option>
              <Select.Option value="timeGridDay">Dia</Select.Option>
              <Select.Option value="multiMonthYear">Ano</Select.Option>
            </Select>
          </Col>
        </Row>
      </Modal>
      <EventDetails
        visible={eventDetailsVisible}
        setVisible={setEventDetailsVisible}
        event={event}
        setEditable={setEditable}
        getEvents={getEvents}
      />
    </>
  );
}
