import { createSlice } from "@reduxjs/toolkit";
import defaultBackgroundImage from "../assets/images/image-default-login.png";
import logoDefault from "../assets/images/LogoIntellect1920x624.svg";

const initialState = {
  backgroundColor: "var(--blue-800)", // cor padrão
  backGroundColorMenu: "var(--blue-900)", // cor padrão
  backGroundColorSubMenu: null, // cor padrão
  backGroundColorSelected: "var(--blue-600)", // cor padrão
  backgroundImage: `url(${defaultBackgroundImage})`, // imagem padrão
  logoImage: logoDefault, // logo padrão
};

const styleSlice = createSlice({
  name: "style",
  initialState,
  reducers: {
    setStyle(state, action) {
      state.backgroundColor = action.payload.backgroundColor;
      state.backGroundColorMenu = action.payload.backGroundColorMenu;
      state.backGroundColorSubMenu = action.payload.backGroundColorSubMenu;
      state.backGroundColorSelected = action.payload.backGroundColorSelected;
      state.backgroundImage = action.payload.backgroundImage;
      state.logoImage = action.payload.logoImage;
    },
  },
});

export const { setStyle } = styleSlice.actions;
export default styleSlice.reducer;
