import { useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Button, Carousel, Image, Modal, notification, Popconfirm, Space, Spin, Tag } from "antd";
import { Calendar, Edit, MapPin, Trash } from "lucide-react";

import moment from "moment";
import Cookies from "js-cookie";

import { RootState } from "../../store/store";
import { ButtonCustom } from "../../components/customButtom/buttomCustom";

import styles from "./events.module.scss";
import { EventsServices } from "../../services/apis/eventsServices";
import { NotificationType } from "../../types/commonTypes";

interface IEditable {
  visible: boolean;
  eventId: number | null;
}

interface NewEventProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setEditable: React.Dispatch<React.SetStateAction<IEditable>>;
  event: any;
  getEvents: () => void;
}

export function EventDetails({
  visible,
  setVisible,
  event,
  setEditable,
  getEvents,
}: Readonly<NewEventProps>) {
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const eventData = event?.event?._def;

  const getUserCookie = Cookies.get("profile");
  const userProfile = JSON.parse(getUserCookie ?? "{}");

  const styleBySubDomain = useSelector((state: RootState) => state.styleSlice);
  const { title = "", extendedProps = {} } = eventData ?? {};
  const {
    description = "",
    accessLink = "",
    location = "",
    movement = "",
    files = [],
    user_id = "",
    event_id = "",
    dateRange = "",
  } = extendedProps;

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  }; 

  const deleteEvent = async () => {
    setLoading(true);
    const eventService = new EventsServices();
    try {
      await eventService.deleteEvent(event_id);
      openNotificationWithIcon("success", "Evento excluído", "Evento excluído com sucesso!");
      setLoading(false);
      setVisible(false);
      setVisibleDelete(false);
      getEvents();
    } catch (error: any) {
      setLoading(false);
      openNotificationWithIcon("error", "Erro ao excluir evento", error.message);
    }
  }

  return (
    <Modal
      title="Detalhes do Evento"
      open={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        setVisible(false);
        setVisibleDelete(false);
      }}
    >
      <Carousel
        autoplay
        infinite
        style={{
          width: "100%",
          padding: "0 20px",
        }}
      >
        {files.map((file: any) => (
          <div key={file.id} className={styles.carouselItem}>
            <Image
              src={file.url_file}
              alt={file.name_document}
              className={styles.carouselImage}
            />
          </div>
        ))}
      </Carousel>

      <div className={styles.infoContainer}>
        <div
          className={styles.infoTitle}
          style={{
            color: styleBySubDomain.backGroundColorMenu,
          }}
        >
          {title}
        </div>
        <div
          className={styles.infoText}
          style={{
            color: styleBySubDomain.backGroundColorMenu,
          }}
        >
          <Tag
            icon={<Calendar style={{ marginRight: "1rem" }} />}
            color={
              styleBySubDomain.backGroundColorMenu === "var(--blue-900)"
                ? "processing"
                : "purple"
            }
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "1rem",
              padding: "0.5rem",
              textWrap: "wrap",
            }}
          >
            {dateRange}
          </Tag>
        </div>
        <div className={styles.infoText}>{description}</div>

        {accessLink && (
          <div className={styles.infoBox}>
            <div className={styles.infoTextBold}>Localização</div>
            <Tag
              color={
                styleBySubDomain.backGroundColorMenu === "var(--blue-900)"
                  ? "processing"
                  : "purple"
              }
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1rem",
                padding: "0.5rem",
                textWrap: "wrap",
              }}
              icon={<MapPin style={{ marginRight: "1rem" }} />}
            >
              {location}
            </Tag>
          </div>
        )}

        <div className={styles.infoBox}>
          <div className={styles.infoTextBold}>Realizador</div>
          <div className={styles.infoText}>{movement}</div>
        </div>

        {accessLink && (
          <div className={styles.infoBox}>
            <div className={styles.infoTextBold}>Link de acesso</div>
            <a
              href={accessLink}
              target="_blank"
              className={styles.infoText}
              rel="noreferrer"
            >
              {accessLink}
            </a>
            <ButtonCustom
              label="Saiba mais!"
              onClick={() => window.open(accessLink, "_blank")}
              style={{
                width: "100%",
                marginTop: "1rem",
                padding: "1.5rem",
              }}
            />
          </div>
        )}
      </div>
      {contextHolder}
      <Spin 
        spinning={loading} 
        fullscreen
        tip="Aguarde..." 
        size="large" 
      />
    </Modal>
  );
}
